import React, { useState } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import map from "../images/map.png"
import Validations from "../components/Validation"
import Schema from "../Data/enquiry.json"
import Service from "../components/Proxy"
import { send } from "emailjs-com"
import moment from "dayjs"

const formFields = {
  name: "",
  email: "",
  message: "",
  phone_no: "",
  ReceivedDate: moment().format("dddd, MMMM D, YYYY"),
  ReceivedTime: moment().format("h:mm:ss A"),
}

function ContactPage() {
  const server = new Service()
  const schema = Schema
  const validator = new Validations()
  const [toSend, setToSend] = useState(formFields)
  const [toerror, setToerror] = useState({
    message: "",
    name: "",
    phone_no: "",
    email: "",
  })
  const [loader, setLoader] = useState(false)

  const error = {}

  const onSubmit = async e => {
    e.preventDefault()
    setLoader(true)
    var filedStatus = await validator.validateSchema(schema, toSend, error)
    setToerror({ ...error })

    if (filedStatus) {
      await server.store(toSend)
      mailService({
        template: "template_1q4zggf",
        form: { ...toSend, admin_email: "" },
      })
      mailService({
        template: "template_9w7cwkr",
        form: { ...toSend, admin_email: "sales@tronstride.com" },
      })
      setToSend({
        name: "",
        email: "",
        message: "",
        phone_no: "",
        ReceivedDate: moment().format("dddd, MMMM D, YYYY"),
        ReceivedTime: moment().format("h:mm:ss A"),
      })
    }
    setLoader(false)
  }

  const mailService = ({ template, form }) => {
    send("service_j57elol", template, form, "user_ZBUyu5c0zvgBIxwT0oEep")
  }

  const handleChange = e => {
    setToSend({ ...toSend, [e.target.name]: e.target.value })
  }
  return (
    <Layout>
      <Seo title="Contact us" />
      <div className="mt-8">
      <div className="font-bold text-3xl lg:text-5xl text-center my-4">
        <span className="text-900">Get in touch, </span>
        <span className="text-blue-600">CONTACT</span>
      </div>
      <div className="grid grid-nogutter surface-0 text-800 px-custom py-custom">
        <div className="col-12 md:col-6 text-center md:text-left flex align-items-center ">
          <ul className="list-none p-0 m-0 flex-grow-1">
            <li className="flex align-items-center mb-4">
              <i
                aria-hidden="true"
                className="fa fa-phone text-color md:text-2xl pr-3"
              ></i>
              <a className="mt-0 text-700 line-height-3 font-medium md:text-xl no-underline" href="tel:+971 58 292 9678">
                +971 58 292 9678
              </a>
            </li>
            <li className="flex align-items-center mb-4">
              <i
                aria-hidden="true"
                className="fa fa-envelope text-color md:text-2xl pr-3"
              ></i>
              <a className="mt-0 text-700 line-height-3 font-medium md:text-xl no-underline" href="mailto:sales@tronstride.com">
                sales@tronstride.com
              </a>
            </li>
            <li className="flex align-items-center mb-4">
              <i
                aria-hidden="true"
                className="fa fa-map-marker text-color md:text-2xl pr-3"
              ></i>
              <a
                className="mt-0 text-700 line-height-3 font-medium md:text-xl map__card__link no-underline"
                href="https://www.google.com/maps/place/SPC+Free+Zone/@25.3199661,55.4558669,17z/data=!3m1!4b1!4m5!3m4!1s0x3e5f5f26195b7e6b:0xc0f6d4eb509bdf75!8m2!3d25.3199661!4d55.4580556"
                target="_blank"
                rel="noreferrer"
              >Sharjah Publishing City FreeZone</a>
            </li>
          </ul>
        </div>
        <div className="col-12 md:col-6 mb-4">
          <div
            className="card p-4"
            style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}
          >
            <form className="formgrid grid" onSubmit={onSubmit}>
              <div className="field col-12 md:col-6">
                <label htmlFor="name" className="textL">
                  Name<span className="required">*</span>
                  <input
                    type="text"
                    id="input"
                    name="name"
                    value={toSend.name}
                    onChange={handleChange}
                    className="form"
                  />
                </label>
                <small id="errname" className="error">
                  {toerror.name}{" "}
                </small>
              </div>
              <div className="field col-12 md:col-6">
                <label htmlFor="phone_no" className="textL">
                  Phone Number<span className="required">*</span>
                  <input
                    id="input"
                    name="phone_no"
                    value={toSend.phone_no}
                    onChange={handleChange}
                    className="form"
                    type="tel"
                    pattern="[7-9]{1}[0-9]{9}"
                    title="Phone number with 7-9 and remaing 9 digit with 0-9"
                  />
                </label>
                <small id="errphone" className="error">
                  {toerror.phone_no}{" "}
                </small>
              </div>
              <div className="field col-12">
                <label htmlFor="message" className="textL">
                  Message<span className="required">*</span>
                  <textarea
                    id="input"
                    name="message"
                    className="form"
                    value={toSend.message}
                    onChange={handleChange}
                  />
                </label>
                <small id="errmessage" className="error">
                  {toerror.message}
                </small>
              </div>
              <div className="field col-12">
                <label htmlFor="email" className="textL">
                  Email<span className="required">*</span>
                  <input
                    id="input"
                    name="email"
                    value={toSend.email}
                    onChange={handleChange}
                    className="form"
                    type="email"
                    list="defaultEmails"
                    multiple
                  />
                </label>
                <small id="errmail" className="error">
                  {toerror.email}{" "}
                </small>
              </div>
              <div className="field col-12">
                <button type="submit" className="btn p-2">
                  <i className={`fa fa-spinner fa-spin ${loader ? "block" : "hidden"}`} />
                  <span className={`text-white text-xl border-round-md ${loader ? "hidden" : "block"}`}>
                    Submit
                  </span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <a
        className="map__card__link"
        href="https://www.google.com/maps/place/SPC+Free+Zone/@25.3199661,55.4558669,17z/data=!3m1!4b1!4m5!3m4!1s0x3e5f5f26195b7e6b:0xc0f6d4eb509bdf75!8m2!3d25.3199661!4d55.4580556"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src={map}
          alt="Sharjah Publishing City FreeZone"
          className="w-screen"
        />
      </a>
      </div>
    </Layout>
  )
}
export default ContactPage
