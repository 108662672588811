

class Validations {
  lang_labels = []
  validateEmail = mail => {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true
    }
    return false
  }
  validateNumber = no => {
    var regexp = /^[0-9]+([,.][0-9]+)?$/g
    var result = regexp.test(no)
    return result
  }

  validateSchema = (schema = {}, data, error) => {
    var flag = true
    Object.keys(schema).forEach(name => {
      var field = schema[name]
      if (field.isObject) {
        field.value = data[name] ? data[name] : null
      } else {
        if (Array.isArray(data[name])) {
          field.value = data[name] ? data[name] : null
        } else {
          field.value = data[name] ? data[name].toString().trim() : null
        }
      }
      var fieldStatus = this.validateFields(field, error)
      field.isValid = fieldStatus
      if (!fieldStatus) {
        flag = false
      }
    })

    return flag
  }

  validateFields = (field, error, state) => {
    var value = field.value,
      name = field.name
    field.isValid = true
    error[name] = ""
    if (field.required || value !== null) {
      if (field.format) {
        var rx = new RegExp(field.format)
        if (rx.test(value)) {
          error[name] = ""
          field.isValid = true
        } else {
          field.isValid = false

          error[name] = "Please enter valid " + field.caption
        }
      }
      if (field.isValid && field.required) {
        if (!value || value === null || value === "") {
          field.isValid = false
          if (field.name === "rterms") {
            error[name] = "Please accept " + field.caption + ""
          } else {
            error[name] = "Please enter " + field.caption + ""
          }
        }
      }
      if (field.isValid && value && field.isNumber) {
        if (this.validateNumber(value) === false) {
          field.isValid = false
          error[name] = field.caption + " should be a number"
        } else {
          var lt, gt
          if (field.lessThan || field.lessThanValue) {
            lt = field.lessThan ? state[field.lessThan] : field.lessThanValue
            if (parseFloat(value) > parseFloat(lt)) {
              field.isValid = false
              error[name] = field.caption + " should be lesser than " + lt
            }
          }
          if (field.greatThan || field.greatThanValue) {
            gt = field.greatThan ? state[field.greatThan] : field.greatThanValue
            if (parseFloat(value) < parseFloat(gt)) {
              field.isValid = false
              error[name] = field.caption + " should be greater than " + gt
            }
          }
        }
      }
      if (field.isValid) {
        if (field.isObject) {
          if (!value || !value[field.idField]) {
            field.isValid = false
            error[name] = field.caption + " is required"
          }
        }
        if (Array.isArray(value)) {
          if (field.required && value && value.length === 0) {
            field.isValid = false
            error[name] = field.caption + " is required"
          }
        }

        
        if (field.maxLength) {
          if (value && value.length > field.maxLength) {
            field.isValid = false
            error[name] =
              "The " +
              field.caption +
              " value must be length of " +
              field.maxLength
          }
        }
        if (field.minLength) {
          if (value && value.length < field.minLength) {
            if (!field.isValid) {
              field.isValid = false
              error[name] = " and should be min length of " + field.minLength
            } else {
              field.isValid = false
              error[name] =
                "The " +
                field.caption +
                " value should be min length of " +
                field.minLength
            }
          }
        }
      }
    }
    if (field.match) {
      if (field.isCase) {
        if (value !== state[field.matchField]) {
          field.isValid = false
          error[name] = field.matchcaption
        }
      } else {
        if (
          !value ||
          !state[field.matchField] ||
          value.toUpperCase() !== state[field.matchField].toUpperCase()
        ) {
          field.isValid = false
          error[name] = field.matchcaption
        }
      }
    } else {
      return field.isValid
    }
    return field.isValid
  }
}
export default Validations
