

class Service {
  // nocodeapi
  store = async data => {

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
        method: "post",
        headers: myHeaders,
        redirect: "follow",
        body: JSON.stringify([data])
    };

    fetch("https://v1.nocodeapi.com/tronstridesales/zohosheet/kTKuQxbjwTxoLODu/sheetData?workSheetName=Tronstride", requestOptions)
        .then(response => response.text())
        .then(result => console.log(result))
        .catch(error => console.log('error', error));

  }


  // store = async data => {
  //
  //   const url = "https://sheet.zoho.com/api/v2/pdbeeafdecc41a94c492889e3738eb63124cd?method=worksheet.records.add&worksheet_name=Tronstride&worksheet_id&"
  //   let response = await axios.post(url,[data],{
  //     headers: {
  //         'Content-Type': 'application/json',
  //         'Authorization': 'Zoho-oauthtoken 1000.c7d9ad790e0587f4a4fa7e7d8f58f6b1.8fa9d22afea55c6d8b2f75d34eb4a488'
  //     }})
  //   return response
  // }
}

export default Service
